import React from 'react';
import style from './modal.module.scss';
import { ModalCancelButton, ModalConfirmButton } from './modal-buttons.jsx';

function Modal({ title, body, cancel, confirm }) {
  return (
    <>
      <div className={style.background} onClick={() => cancel()}></div>
      <div className={style.window}>
        <div className={style.header}>
          <h2>{title}</h2>
        </div>
        <div className={style.body}>{body}</div>
        <div className={style.footer}>
          <ModalCancelButton label="Cancel" icon="XCircle" action={cancel} />
          <ModalConfirmButton
            label="Confirm"
            icon="CheckCircle"
            action={confirm}
          />
        </div>
      </div>
    </>
  );
}

export default Modal;
