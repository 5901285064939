import React, { useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import api from '../../services/axios-calls';
import errorMessageParser from '../../utils/error-parser';
import NavigationButton from '../buttons/navigation-button';
import { useHistory } from 'react-router-dom';
import FormSubmitButton from '../buttons/form-submit-button';

function AddScope() {
  const [name, setName] = useState('');
  const history = useHistory();

  // add scope to server
  const queryClient = useQueryClient();
  const addScope = useMutation(async () => await api.createScope(name), {
    onSuccess: () => queryClient.invalidateQueries('scopes'),
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    addScope
      .mutateAsync()
      .then(() => {
        setName('');
        history.goBack();
      })
      .catch((error) => console.log(error.message));
  };

  // render form
  return (
    <div>
      <NavigationButton
        label="Back"
        icon="CornerUpLeft"
        to={() => history.goBack()}
      />
      <h1>Add new permission scope</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Name</label>
        <input
          id="name"
          type="text"
          name="name"
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        <FormSubmitButton label="Add scope" icon="PlusSquare" />
      </form>
      {addScope.isError && (
        <>
          {errorMessageParser(addScope.error).map((message) => (
            <p>{message}</p>
          ))}
        </>
      )}
    </div>
  );
}

export default AddScope;
