import React, { useState, useEffect } from 'react';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import api from '../../services/axios-calls';
import { initScopeState } from '../../utils/create-checkboxes';
import errorMessageParser from '../../utils/error-parser';
import { useHistory } from 'react-router-dom';
import NavigationButton from '../buttons/navigation-button';
import FormSubmitButton from '../buttons/form-submit-button';

function AddRole() {
  const [name, setName] = useState('');
  const [scopes, setScopes] = useState([]);
  const history = useHistory();

  // fetch scopes from server
  const scopesQuery = useQuery(['scopes'], api.getAllScopes);

  // add role to server
  const queryClient = useQueryClient();
  const addRole = useMutation(
    async () => {
      const sendingScopes = scopesQuery.data.scopes.filter((scope, index) => {
        return scopes[index].isChecked;
      });
      await api.createRole(name, sendingScopes);
    },
    {
      onSuccess: () => queryClient.invalidateQueries('roles'),
    },
  );

  // handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    addRole
      .mutateAsync()
      .then(() => {
        setName('');
        setScopes(initScopeState(scopesQuery.data.scopes));
        history.goBack();
      })
      .catch((error) => console.log(error.message));
  };

  // add scopes to form
  useEffect(() => {
    if (scopesQuery.isSuccess && scopesQuery.data) {
      setScopes(initScopeState(scopesQuery.data.scopes));
    }
  }, [scopesQuery.isSuccess, scopesQuery.data]);

  // error checking
  if (scopesQuery.isLoading) return <p>Loading...</p>;
  if (scopesQuery.isError) return <p>Error: {scopesQuery.error.message}</p>;

  // render form
  return (
    <div>
      <NavigationButton
        label="Back"
        icon="CornerUpLeft"
        to={() => history.goBack()}
      />
      <h1>Add new role</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Name</label>
        <input
          id="name"
          type="text"
          name="name"
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        {scopes.map((scope, index) => {
          return (
            <div key={`${scope}-${index}`}>
              <input
                id={`${scope}-${index}`}
                name={scope.name}
                type="checkbox"
                checked={scope.isChecked}
                onChange={() => {
                  const arr = [...scopes];
                  const value = !scopes[index].isChecked;
                  arr[index].isChecked = value;
                  setScopes(arr);
                }}
              />
              <label htmlFor={`${scope}-${index}`}>{scope.name}</label>
            </div>
          );
        })}
        <FormSubmitButton label="Add role" icon="PlusSquare" />
      </form>
      {addRole.isError && (
        <>
          {errorMessageParser(addRole.error).map((message) => (
            <p>{message}</p>
          ))}
        </>
      )}
    </div>
  );
}

export default AddRole;
