export const sideNavData = [
  {
    label: 'Home',
    icon: 'Home',
    link: '/',
    scope: null,
  },
  {
    label: 'Files',
    icon: 'File',
    link: '/files',
    scope: 'read:files',
  },
  {
    label: 'Folders',
    icon: 'Folder',
    link: '/folders',
    scope: 'read:folders',
  },

  {
    label: 'Users',
    icon: 'Users',
    link: '/users',
    scope: 'read:users',
  },
  {
    label: 'Roles',
    icon: 'Key',
    link: '/roles',
    scope: 'read:roles',
  },
  {
    label: 'Scopes',
    icon: 'Terminal',
    link: '/scopes',
    scope: 'read:scopes',
  },
];
