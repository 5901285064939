import React from 'react';
import styles from './buttons.module.scss';
import Icon from '../navbar/icon.jsx';

function NavigationButton(props) {
  const { label, icon, to } = props;
  return (
    <div className={styles.navButton} onClick={to}>
      {icon && <Icon icon={icon} />}
      <span>{label}</span>
    </div>
  );
}

export default NavigationButton;
