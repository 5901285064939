import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './navbar.module.scss';
import { sideNavData } from './nav-data';
import { useAuthProvider } from '../../auth/auth-state';
import useViewport from '../../hooks/useViewport';
import Icon from './icon.jsx';
import { v4 as uuidv4 } from 'uuid';

function SideNav() {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const { scopes } = useAuthProvider();
  const { width } = useViewport();
  const breakpoint = 992; // medium from palette/_breakpoints.scss

  // force sidemenu open on widescreens
  useEffect(() => {
    if (width >= breakpoint) {
      setSideMenuOpen(true);
    }
    if (width < breakpoint) {
      setSideMenuOpen(false);
    }
  }, [width]);

  // Side menu controls
  const toggleSideMenu = () => {
    if (width < breakpoint) {
      setSideMenuOpen(!sideMenuOpen);
    }
  };

  // close menu onclick in mobile view
  const closeSideMenu = () => {
    if (width < breakpoint) {
      setSideMenuOpen(false);
    }
  };

  // Build Side Menu
  const sideMenu = sideNavData
    .filter((item) => {
      // if item doesn't require scope, include it
      if (item.scope === null) return true;
      // filter items if scope isn't present
      else return scopes.includes(item.scope);
    })
    .map((item) => {
      return (
        <Link
          key={uuidv4()}
          to={item.link}
          onClick={closeSideMenu}
          className={styles.item}
        >
          <Icon icon={item.icon} />
          <div className={styles.label}>{item.label}</div>
        </Link>
      );
    });

  return (
    <>
      {/* Toggle */}
      {width < breakpoint ? (
        <div className={styles.sideToggle} onClick={toggleSideMenu}>
          <Icon icon="Menu" />
        </div>
      ) : null}

      {/* Menu */}
      {sideMenuOpen ? (
        <nav className={styles.sideMenu}>{sideMenu}</nav>
      ) : (
        <nav className={`${styles.sideMenu} ${styles.hide}`}>{sideMenu}</nav>
      )}
    </>
  );
}

export default SideNav;
