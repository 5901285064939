import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import FormSubmitButton from '../buttons/form-submit-button';
import NavigationButton from '../buttons/navigation-button';
import api from '../../services/axios-calls';
import { useAuthProvider } from '../../auth/auth-state';

function EditFolder() {
  const { checkSession } = useAuthProvider();
  // form state
  const [newFolderName, setNewFolderName] = useState('');
  const [message, setMessage] = useState('');

  // get query parameter from url
  const { name } = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();

  const editFolder = useMutation(
    async () => {
      const formData = {
        newFolderName: newFolderName,
      };
      await api.updateFolder(name, formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['afolders']);
        checkSession();
      },
    },
  );

  function handleSubmit(event) {
    event.preventDefault();
    editFolder
      .mutateAsync()
      .then(() => {
        history.goBack();
      })
      .catch((error) => {
        console.log(error.message);
      });
    setMessage('');
  }

  return (
    <div>
      <NavigationButton
        label="Back"
        icon="CornerUpLeft"
        to={() => history.goBack()}
      />
      <h1>Edit folder: {name}</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="username">New folder name</label>
        <input
          id="foldername"
          type="text"
          name="foldername"
          value={newFolderName}
          onChange={(event) => {
            setNewFolderName(event.target.value);
          }}
        />

        <FormSubmitButton label="Edit folder" icon="Folder" />
      </form>
      <p>{message}</p>
    </div>
  );
}

export default EditFolder;
