import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import api from '../../services/axios-calls';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { initRoleState } from '../../utils/create-checkboxes';
import { useAuthProvider } from '../../auth/auth-state';
import { v4 as uuidv4 } from 'uuid';
import NavigationButton from '../buttons/navigation-button';
import FormSubmitButton from '../buttons/form-submit-button';
import errorMessageParser from '../../utils/error-parser';

function EditUser() {
  // form state
  const [newUsername, setNewUsername] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newRoles, setNewRoles] = useState([]);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  // get query parameter from url
  const { username } = useParams();
  const history = useHistory();

  // fetch data from the server
  const rolesQuery = useQuery(['roles'], api.getAllRoles);
  const userQuery = useQuery(['users', username], () => api.getUser(username));
  const queryClient = useQueryClient();
  const { checkSession } = useAuthProvider();

  // mutate server state
  const editUser = useMutation(
    async () => {
      const sendingRoles = rolesQuery.data.roles.filter((role, index) => {
        return newRoles[index].isChecked;
      });
      const formData = {
        newUsername,
        newEmail,
        newPassword,
        newRole: sendingRoles,
      };
      await api.updateUser(userQuery.data.users[0].username, formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['users']);
        checkSession();
      },
    },
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newPassword === confirmPassword) {
      editUser
        .mutateAsync()
        .then(() => {
          history.goBack();
        })
        .catch((error) => {
          console.log(error.message);
        });
      setMessage('');
    } else {
      setMessage('Confirm new password.');
    }
  };

  // init form data and update it on refetches
  useEffect(() => {
    if (userQuery.isSuccess && userQuery.data !== undefined) {
      if (userQuery.data.users[0]) {
        setNewUsername(userQuery.data.users[0].username);
        setNewEmail(userQuery.data.users[0].email);
      }
    }
    if (
      userQuery.isSuccess &&
      rolesQuery.isSuccess &&
      userQuery.data !== undefined &&
      rolesQuery.data !== undefined
    ) {
      setNewRoles(
        initRoleState(rolesQuery.data.roles, userQuery.data.users[0]),
      );
    }
  }, [
    userQuery.data,
    userQuery.isSuccess,
    rolesQuery.data,
    rolesQuery.isSuccess,
  ]);

  // error checking
  if (rolesQuery.isLoading || userQuery.isLoading) return <p>Loading...</p>;
  if (userQuery.isError) return <p>{errorMessageParser(userQuery.error)}</p>;
  if (rolesQuery.isError) return <p>{errorMessageParser(rolesQuery.error)}</p>;

  return (
    <div>
      <NavigationButton
        label="Back"
        icon="CornerUpLeft"
        to={() => history.goBack()}
      />
      <h1>Edit user</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="username">Username</label>
        <input
          id="username"
          type="text"
          name="username"
          value={newUsername}
          onChange={(event) => {
            setNewUsername(event.target.value);
          }}
        />
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          name="email"
          value={newEmail}
          onChange={(event) => {
            setNewEmail(event.target.value);
          }}
        />
        <label htmlFor="new-password">New Password</label>
        <input
          id="new-password"
          type="password"
          name="new-password"
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
        />
        <label htmlFor="confirm-password">Confirm Password</label>
        <input
          id="confirm-password"
          type="password"
          name="confirm-password"
          value={confirmPassword}
          onChange={(event) => setConfirmPassword(event.target.value)}
        />
        <span>Roles</span>
        {newRoles.map((role, index) => {
          return (
            <div key={uuidv4()}>
              <input
                id={`${role}-${index}`}
                name={role.name}
                type="checkbox"
                checked={role.isChecked}
                onChange={() => {
                  const arr = [...newRoles];
                  const value = !newRoles[index].isChecked;
                  arr[index].isChecked = value;
                  setNewRoles(arr);
                }}
              />
              <label htmlFor={`${role}-${index}`}>{role.name}</label>
            </div>
          );
        })}
        <FormSubmitButton label="Edit user" icon="UserCheck" />
      </form>
      <p>{message}</p>
    </div>
  );
}

export default EditUser;
