import { createContext, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import axios from 'axios';
import errorMessageParser from '../utils/error-parser';

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [scopes, setScopes] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);

  const history = useHistory();
  const queryClient = useQueryClient();

  // login function
  const login = async (username, password) => {
    try {
      // get session cookie from the backend
      const response = await axios.post('/auth/login', {
        username,
        password,
      });

      // reset error field
      setErrorMessages([]);

      // get username to display
      setUser(response.data.user.username);

      // get scopes for access
      const roles = response.data.user.role;
      let newScopes = [];
      roles.forEach((role) => {
        role.scope.forEach((scope) => {
          newScopes.push(scope.name);
        });
      });
      setScopes(newScopes);

      // go to home
      history.push('/');
    } catch (error) {
      // set error message to display
      setErrorMessages(errorMessageParser(error));
    }
  };

  // logout function
  const logout = async () => {
    try {
      if (user) {
        // clear session cookie
        await axios.get('/auth/logout');

        // redirect to login first so error private route checks don't trigger
        history.push('/login');

        // clear cache so next user won't see cached data
        queryClient.clear();

        // reset auth information
        setUser(null);
        setScopes([]);
        setErrorMessages([]);
      }
    } catch (error) {
      setErrorMessages(errorMessageParser(error));
    }
  };

  // refresh session
  const checkSession = async () => {
    // check if user already exists in context and return if so
    // if (user) return true;

    try {
      // check server for active session
      const response = await axios.get('/auth/session');

      // get username to display
      setUser(response.data.user.username);

      // get scopes for access
      const roles = response.data.user.role;
      let newScopes = [];
      roles.forEach((role) => {
        role.scope.forEach((scope) => {
          newScopes.push(scope.name);
        });
      });
      setScopes(newScopes);
    } catch (error) {
      setErrorMessages(errorMessageParser(error));
      await logout();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        scopes,
        errors: errorMessages,
        setUser,
        login,
        logout,
        checkSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuthProvider() {
  const auth = useContext(AuthContext);
  return auth;
}

export { AuthProvider, useAuthProvider };
