import React from 'react';
import styles from './modal.module.scss';
import Icon from '../navbar/icon.jsx';

export function ModalConfirmButton(props) {
  const { label, icon, action } = props;
  return (
    <button className={styles.confirm} onClick={action}>
      {icon && <Icon icon={icon} />}
      <span>{label}</span>
    </button>
  );
}

export function ModalCancelButton(props) {
  const { label, icon, action } = props;
  return (
    <button className={styles.cancel} onClick={action}>
      {icon && <Icon icon={icon} />}
      <span>{label}</span>
    </button>
  );
}
