import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import api from '../../services/axios-calls';
import Modal from '../modal/modal-window.jsx';
import errorMessageParser from '../../utils/error-parser';
import { v4 as uuidv4 } from 'uuid';
import styles from './scopes.module.scss';
import { Trash2 } from 'react-feather';
import NavigationButton from '../buttons/navigation-button';
import { useAuthProvider } from '../../auth/auth-state';

function ScopeList() {
  const { scopes } = useAuthProvider();
  const [openModal, setOpenModal] = useState(false);
  const [selectedScope, setSelectedScope] = useState(null);
  const history = useHistory();
  const { data, error, isLoading, isError } = useQuery(
    ['scopes'],
    api.getAllScopes,
  );

  // handle deleting scopes
  const queryClient = useQueryClient();
  const deleteScope = useMutation(api.deleteScope, {
    onSuccess: () => queryClient.invalidateQueries('scopes'),
  });

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>{errorMessageParser(error)}</p>;

  return (
    <>
      <h1>Scopes</h1>
      <p>This is a list of all scopes in the system.</p>
      {scopes.includes('create:scopes') && (
        <NavigationButton
          label="Add new scope"
          icon="PlusSquare"
          to={() => history.push('/scopes/create')}
        />
      )}

      <table className={styles.list}>
        <thead>
          <tr>
            <th>Scope</th>
            {scopes.includes('delete:scopes') && <th>Delete</th>}
          </tr>
        </thead>
        <tbody>
          {data.scopes.map((scope, index) => {
            return (
              <tr key={uuidv4()}>
                <td onClick={() => history.push(`/scopes/${scope.name}`)}>
                  {scope.name}
                </td>
                {scopes.includes('delete:scopes') && (
                  <td
                    className="trash"
                    onClick={() => {
                      setOpenModal(true);
                      setSelectedScope(scope.name);
                    }}
                  >
                    <Trash2 />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {openModal && (
        <Modal
          title="Delete scope"
          body="Are you sure you want to delete this scope?"
          cancel={() => {
            setOpenModal(false);
            setSelectedScope(null);
          }}
          confirm={() => {
            setOpenModal(false);
            deleteScope.mutate(selectedScope);
            setSelectedScope(null);
          }}
        />
      )}
    </>
  );
}

export default ScopeList;
