/**
 * Creates an array of objects that contain a boolean that
 * can be used as a controlled state for checkboxes in a form.
 *
 * @param {Array} roles - Array of roles from the server
 * @param {Object} user - User object from the server
 * @returns Array of Objects with name and and a boolean
 */
export function initRoleState(roles, user = null) {
  let initialRoles = [];
  roles.forEach((role) => {
    const addRole = {
      name: role.name,
      isChecked: user
        ? user.role.some((userRole) => userRole.name === role.name)
        : false,
    };
    initialRoles.push(addRole);
  });
  return initialRoles;
}

export function initScopeState(scopes, role) {
  let initialScopes = [];
  scopes.forEach((scope) => {
    const addScope = {
      name: scope.name,
      isChecked: role
        ? role.scope.some((roleScope) => roleScope.name === scope.name)
        : false,
    };
    initialScopes.push(addScope);
  });
  return initialScopes;
}
