import React from 'react';
import styles from './footer.module.scss';

function Footer() {
  return (
    <footer className={styles.footer}>
      <a href="https://osuuskuntaverkottaja.fi/">
        &#169; 2021 Osuuskunta Verkottaja
      </a>
    </footer>
  );
}

export default Footer;
