import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import api from '../../services/axios-calls';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import FormSubmitButton from '../buttons/form-submit-button';
import NavigationButton from '../buttons/navigation-button';
import { useAuthProvider } from '../../auth/auth-state';

function EditScope() {
  const { checkSession } = useAuthProvider();

  // form state
  const [newName, setNewName] = useState('');

  // get query parameter from url
  const { name } = useParams();
  const history = useHistory();

  // fetch data from the server
  const scopeQuery = useQuery(['scopes', name], () => api.getScope(name));
  const queryClient = useQueryClient();

  // mutate server state
  const editScope = useMutation(
    async () => {
      const formData = {
        newName,
      };
      await api.updateScope(scopeQuery.data.scopes[0].name, formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['users']);
        checkSession();
      },
    },
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    editScope
      .mutateAsync()
      .then(() => {
        history.goBack();
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  // init form data and update it on refetches
  useEffect(() => {
    if (scopeQuery.isSuccess && scopeQuery.data !== undefined) {
      setNewName(scopeQuery.data.scopes[0].name);
    }
  }, [scopeQuery.data, scopeQuery.isSuccess]);

  // error checking
  if (scopeQuery.isLoading) return <p>Loading...</p>;
  if (scopeQuery.isError) return <p>Error: {scopeQuery.error.message}</p>;

  return (
    <div>
      <NavigationButton
        label="Back"
        icon="CornerUpLeft"
        to={() => history.goBack()}
      />
      <h1>Edit scope</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Name</label>
        <input
          id="name"
          type="text"
          name="name"
          value={newName}
          onChange={(event) => {
            setNewName(event.target.value);
          }}
        />
        <FormSubmitButton label="Edit scope" icon="CheckSquare" />
      </form>
    </div>
  );
}

export default EditScope;
