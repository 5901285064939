import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthProvider } from '../../auth/auth-state';
import NavigationButton from '../buttons/navigation-button';
import Image from '../../assets/Toimisto.jpg';

export default function Home() {
  const { user, logout } = useAuthProvider();
  const history = useHistory();
  return (
    <div>
      <h1>Home</h1>
      {user ? (
        <NavigationButton label="Log out" icon="LogOut" to={logout} />
      ) : (
        <NavigationButton
          label="Log in"
          icon="LogIn"
          to={() => history.push('/login')}
        />
      )}
      <div style={{ maxWidth: '700px', overflow: 'hidden' }}>
        <img src={Image} alt="" width="700px" />
      </div>
    </div>
  );
}
