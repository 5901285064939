import React, { useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import api from '../../services/axios-calls';
import { useAuthProvider } from '../../auth/auth-state';
import errorMessageParser from '../../utils/error-parser';
import NavigationButton from '../buttons/navigation-button';
import FormSubmitButton from '../buttons/form-submit-button';
import { useHistory } from 'react-router-dom';

function AddFolder() {
  const [name, setName] = useState('');
  const { checkSession } = useAuthProvider();
  const history = useHistory();

  // add folder to server
  const queryClient = useQueryClient();
  const addFolder = useMutation(async () => await api.createFolder(name), {
    onSuccess: () => {
      queryClient.invalidateQueries('folders');
      checkSession();
    },
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    addFolder
      .mutateAsync()
      .then(() => {
        setName('');
        history.goBack();
      })
      .catch((error) => console.log(error.message));
  };

  // render form
  return (
    <div>
      <NavigationButton
        label="Back"
        icon="CornerUpLeft"
        to={() => history.goBack()}
      />
      <h1>Add new folder</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Name</label>
        <input
          id="name"
          type="text"
          name="name"
          value={name}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        <FormSubmitButton label="Add folder" icon="FolderPlus" />
      </form>
      {addFolder.isError && (
        <>
          {errorMessageParser(addFolder.error).map((message) => (
            <p>{message}</p>
          ))}
        </>
      )}
    </div>
  );
}

export default AddFolder;
