import React, { useState, useEffect } from 'react';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import api from '../../services/axios-calls';
import { initRoleState } from '../../utils/create-checkboxes';
// import styles from './users.module.scss';
import NavigationButton from '../buttons/navigation-button';
import { useHistory } from 'react-router-dom';
import FormSubmitButton from '../buttons/form-submit-button';
import errorMessageParser from '../../utils/error-parser';

function AddUser() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [roles, setRoles] = useState([]);

  const history = useHistory();

  // fetch roles from server
  const rolesQuery = useQuery(['roles'], api.getAllRoles);

  // add user to server
  const queryClient = useQueryClient();
  const addUser = useMutation(
    async () => {
      const sendingRoles = rolesQuery.data.roles.filter((role, index) => {
        return roles[index].isChecked;
      });
      // console.log(sendingRoles);
      await api.createUser(username, email, password, sendingRoles);
    },
    {
      onSuccess: () => queryClient.invalidateQueries('users'),
    },
  );
  const handleSubmit = (event) => {
    event.preventDefault();
    addUser
      .mutateAsync()
      .then(() => {
        setUsername('');
        setEmail('');
        setPassword('');
        setRoles(initRoleState(rolesQuery.data.roles));
        history.goBack();
      })
      .catch((error) => console.log(error.message));
  };

  // add roles to form
  useEffect(() => {
    if (rolesQuery.isSuccess && rolesQuery.data) {
      setRoles(initRoleState(rolesQuery.data.roles));
    }
  }, [rolesQuery.isSuccess, rolesQuery.data]);

  // error checking
  if (rolesQuery.isLoading) return <p>Loading...</p>;
  if (rolesQuery.isError) return <p>Error: {rolesQuery.error.message}</p>;

  // render form
  return (
    <div>
      <NavigationButton
        label="Back"
        icon="CornerUpLeft"
        to={() => history.goBack()}
      />
      <h1>Add new user</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="username">Username</label>
        <input
          id="username"
          type="text"
          name="username"
          value={username}
          onChange={(event) => {
            setUsername(event.target.value);
          }}
        />
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          name="email"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <label htmlFor="password">Password</label>
        <input
          id="password"
          type="password"
          name="password"
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        />

        <p>Roles</p>
        {roles.map((role, index) => {
          return (
            <div key={`${role}-${index}`}>
              <input
                className="checkbox"
                id={`${role}-${index}`}
                name={role.name}
                type="checkbox"
                checked={role.isChecked}
                onChange={() => {
                  const arr = [...roles];
                  const value = !roles[index].isChecked;
                  arr[index].isChecked = value;
                  setRoles(arr);
                }}
              />
              <label htmlFor={`${role}-${index}`}>{role.name}</label>
            </div>
          );
        })}
        <FormSubmitButton label="Add user" icon="UserPlus" />
      </form>
      {addUser.isError && (
        <>
          {errorMessageParser(addUser.error).map((message) => (
            <p>{message}</p>
          ))}
        </>
      )}
      {rolesQuery.isError && (
        <>
          {errorMessageParser(rolesQuery.error).map((message) => (
            <p>{message}</p>
          ))}
        </>
      )}
    </div>
  );
}

export default AddUser;
