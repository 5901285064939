import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useAuthProvider } from '../../auth/auth-state';
import api from '../../services/axios-calls';
import FormSubmitButton from '../buttons/form-submit-button';
import errorMessageParser from '../../utils/error-parser';

function Profile() {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const auth = useAuthProvider();
  const profile = useQuery(['profile'], () => api.getProfile());
  const changePassword = useMutation(
    async () => await api.changePassword(auth.user, password, newPassword),
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newPassword === confirmPassword) {
      changePassword
        .mutateAsync()
        .then(() => {
          setPassword('');
          setNewPassword('');
          setConfirmPassword('');
          setMessage('Password changed.');
        })
        .catch((error) => console.log(error));
    } else {
      setMessage('Confirm new password.');
    }
  };

  if (profile.isLoading) return <p>Loading...</p>;
  if (profile.isError) return <p>Server error.</p>;

  return (
    <div>
      <h1>Profile</h1>
      <p>Username: {profile.data.users[0].username}</p>
      <p>Email: {profile.data.users[0].email}</p>
      <form onSubmit={handleSubmit}>
        <label htmlFor="old-password">Current Password</label>
        <input
          type="password"
          required
          value={password}
          id="old-password"
          onChange={(event) => setPassword(event.target.value)}
        />
        <label htmlFor="new-password">New Password</label>
        <input
          type="password"
          required
          value={newPassword}
          id="new-password"
          onChange={(event) => setNewPassword(event.target.value)}
        />
        <label htmlFor="confirm-password">Confirm New Password</label>
        <input
          type="password"
          required
          value={confirmPassword}
          id="confirm-password"
          onChange={(event) => setConfirmPassword(event.target.value)}
        />
        <FormSubmitButton label="Change Password" icon="UserCheck" />
      </form>
      <p>{message}</p>
      {changePassword.isError && (
        <>
          {errorMessageParser(changePassword.error).map((message) => (
            <p>{message}</p>
          ))}
        </>
      )}
    </div>
  );
}

export default Profile;
