import React from 'react';
import styles from './layout.module.scss';
import Navbar from '../navbar/navbar.jsx';
import Footer from '../footer/footer.jsx';

function Layout({ children }) {
  return (
    <div className={styles.wrapper}>
      <Navbar />
      <div className={styles.main}>
        <main className={styles.content}>{children}</main>
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
