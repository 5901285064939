import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import api from '../../services/axios-calls';
import Modal from '../modal/modal-window.jsx';
import errorMessageParser from '../../utils/error-parser';
import { v4 as uuidv4 } from 'uuid';
import styles from './roles.module.scss';
import { Trash2 } from 'react-feather';
import NavigationButton from '../buttons/navigation-button';
import { useAuthProvider } from '../../auth/auth-state';

function RoleList() {
  const { scopes } = useAuthProvider();
  const [openModal, setOpenModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const history = useHistory();
  const { data, error, isLoading, isError } = useQuery(
    ['roles'],
    api.getAllRoles,
  );

  // handle deleting roles
  const queryClient = useQueryClient();
  const deleteRole = useMutation(api.deleteRole, {
    onSuccess: () => queryClient.invalidateQueries('roles'),
  });

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>{errorMessageParser(error)}</p>;

  return (
    <>
      <h1 className={styles.title}>Roles</h1>
      <p>This is a list of all roles in the system.</p>
      {scopes.includes('create:roles') && (
        <NavigationButton
          label="Add new role"
          icon="PlusSquare"
          to={() => history.push('/roles/create')}
        />
      )}
      {deleteRole.isError && (
        <div style={{ color: 'red' }}>
          Error: {errorMessageParser(deleteRole.error)}
        </div>
      )}
      <table className={styles.list}>
        <thead>
          <tr>
            <th>Role</th>
            {scopes.includes('delete:roles') && <th>Delete</th>}
          </tr>
        </thead>
        <tbody>
          {data.roles.map((role) => {
            return (
              <tr key={uuidv4()}>
                <td onClick={() => history.push(`/roles/${role.name}`)}>
                  {role.name}
                </td>
                {scopes.includes('delete:roles') && (
                  <td
                    className="trash"
                    onClick={() => {
                      setOpenModal(true);
                      setSelectedRole(role.name);
                    }}
                  >
                    <Trash2 />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {openModal && (
        <Modal
          title="Delete role"
          body="Are you sure you want to delete this role?"
          cancel={() => {
            setOpenModal(false);
            setSelectedRole(null);
          }}
          confirm={() => {
            setOpenModal(false);
            deleteRole.mutate(selectedRole);
            setSelectedRole(null);
          }}
        />
      )}
    </>
  );
}

export default RoleList;
