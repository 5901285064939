import axios from 'axios';

const api = {};

/* === USER CRUD ===
================================================== */
api.createUser = async function (username, email, password, role) {
  const { data } = await axios.post('/users', {
    username,
    email,
    password,
    role,
  });
  return data;
};

api.getUser = async function (username) {
  const { data } = await axios.get(`/users?username=${encodeURI(username)}`);
  return data;
};

api.getAllUsers = async function () {
  const { data } = await axios.get('/users');
  return data;
};

api.updateUser = async function (username, formData) {
  // make update object
  const update = {};
  if (formData.newUsername) update.newUsername = formData.newUsername;
  if (formData.newEmail) update.newEmail = formData.newEmail;
  if (formData.newPassword) update.newPassword = formData.newPassword;
  if (formData.newRole) update.newRole = formData.newRole;

  const { data } = await axios.patch(`/users/${encodeURI(username)}`, update);
  return data;
};

api.deleteUser = async function (username) {
  const { data } = await axios.delete(`/users/${encodeURI(username)}`);
  return data;
};

api.getProfile = async function () {
  const { data } = await axios.get(`/users/profile`);
  return data;
};

api.changePassword = async function (username, password, newPassword) {
  const { data } = await axios.patch(`/auth/changepassword`, {
    username,
    password,
    newPassword,
  });
  return data;
};

/* === ROLE CRUD ===
================================================== */
api.createRole = async function (name, scope) {
  const { data } = await axios.post(`/roles`, {
    name,
    scope,
  });
  return data;
};

api.getRole = async function (name) {
  const { data } = await axios.get(`/roles?name=${encodeURI(name)}`);
  return data;
};

api.getAllRoles = async function () {
  const { data } = await axios.get('/roles');
  return data;
};

api.updateRole = async function (name, formData) {
  // make update object
  const update = {};
  if (formData.newName) update.newName = formData.newName;
  if (formData.newScope) update.newScope = formData.newScope;

  const { data } = await axios.patch(`/roles/${encodeURI(name)}`, update);
  return data;
};

api.deleteRole = async function (name) {
  const { data } = await axios.delete(`/roles/${encodeURI(name)}`);
  return data;
};

/* === SCOPE CRUD ===
================================================== */
api.createScope = async function (name) {
  const { data } = await axios.post(`/scopes`, { name });
  return data;
};

api.getScope = async function (name) {
  const { data } = await axios.get(`/scopes?name=${encodeURI(name)}`);
  return data;
};

api.getAllScopes = async function () {
  const { data } = await axios.get('/scopes');
  return data;
};

api.updateScope = async function (name, formData) {
  // make update object
  const update = {};
  if (formData.newName) update.newName = formData.newName;

  const { data } = await axios.patch(`/scopes?name=${encodeURI(name)}`, update);
  return data;
};

api.deleteScope = async function (name) {
  const { data } = await axios.delete(`/scopes?name=${encodeURI(name)}`);
  return data;
};

/* === File CRUD ===
================================================== */
api.createFile = async function (folderName, file) {
  const formData = new FormData();
  formData.append('file', file);
  const { data } = await axios.post(
    `/files/${encodeURI(folderName)}`,
    formData,
  );
  return data;
};

api.getAllFilesInFolder = async function (folderName) {
  const { data } = await axios.get(`/files/${encodeURI(folderName)}/`);
  return data;
};

api.getAllFileFolders = async function () {
  const { data } = await axios.get('/files');
  return data;
};

api.deleteFile = async function (folderName, fileName) {
  const { data } = await axios.delete(
    `/files/${encodeURI(folderName)}/${encodeURI(fileName)}`,
  );
  return data;
};

api.downloadFile = async function (folderName, fileName) {
  const { data } = await axios.get(
    `/files/${encodeURI(folderName)}/${encodeURI(fileName)}/download`,
  );
  return data;
};

/* === Folder CRUD ===
================================================== */
api.createFolder = async function (folderName) {
  const { data } = await axios.post(`/folders`, { folderName });
  return data;
};

api.getFolder = async function (name) {
  const { data } = await axios.get(`/folders/${encodeURI(name)}`);
  return data;
};

api.getAllFolders = async function () {
  const { data } = await axios.get('/folders');
  return data;
};

api.updateFolder = async function (name, formData) {
  // make update object
  const update = {};
  if (formData.newFolderName) update.newFolderName = formData.newFolderName;

  const { data } = await axios.patch(`/folders/${encodeURI(name)}`, update);
  return data;
};

api.deleteFolder = async function (folderName) {
  const { data } = await axios.delete(`/folders/${encodeURI(folderName)}`);
  return data;
};

export default api;
