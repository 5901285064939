import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../services/axios-calls';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import Modal from '../modal/modal-window.jsx';
import { v4 as uuidv4 } from 'uuid';
import styles from './users.module.scss';
import { Trash2 } from 'react-feather';
import NavigationButton from '../buttons/navigation-button';
import { useAuthProvider } from '../../auth/auth-state';
import errorMessageParser from '../../utils/error-parser';

function UserList() {
  const { scopes } = useAuthProvider();
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const history = useHistory();
  const userList = useQuery(['users'], api.getAllUsers);

  // handle deleting users
  const queryClient = useQueryClient();
  const deleteUser = useMutation(api.deleteUser, {
    onSuccess: () => queryClient.invalidateQueries('users'),
  });

  // error checking
  if (userList.isLoading) return <p>Loading...</p>;
  if (userList.isError) return <p>Server error.</p>;

  return (
    <>
      <h1>Users</h1>
      <p>This is a list of all users in the system.</p>
      {scopes.includes('create:users') && (
        <NavigationButton
          label="Add new user"
          icon="PlusSquare"
          to={() => history.push('/users/create')}
        />
      )}
      {deleteUser.isError && (
        <div style={{ color: 'red' }}>
          {' '}
          Error: {errorMessageParser(deleteUser.error)}
        </div>
      )}
      <table className={styles.list}>
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            {scopes.includes('delete:users') && <th>Delete</th>}
          </tr>
        </thead>
        <tbody>
          {userList.data.users.map((user) => (
            <tr key={uuidv4()}>
              <td onClick={() => history.push(`/users/${user.username}`)}>
                {user.username}
              </td>
              <td onClick={() => history.push(`/users/${user.username}`)}>
                {user.email}
              </td>
              {scopes.includes('delete:users') && (
                <td
                  className="trash"
                  onClick={() => {
                    setOpenModal(true);
                    setSelectedUser(user.username);
                  }}
                >
                  <Trash2 />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {openModal && (
        <Modal
          title="Delete user"
          body="Are you sure you want to delete this user?"
          cancel={() => {
            setOpenModal(false);
            setSelectedUser(null);
          }}
          confirm={() => {
            setOpenModal(false);
            deleteUser.mutate(selectedUser);
            setSelectedUser(null);
          }}
        />
      )}
    </>
  );
}

export default UserList;
