import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import api from '../../services/axios-calls';
import Modal from '../modal/modal-window.jsx';
import errorMessageParser from '../../utils/error-parser';
import { v4 as uuidv4 } from 'uuid';
import styles from './files.module.scss';
import { Trash2 } from 'react-feather';
import { useAuthProvider } from '../../auth/auth-state';
import NavigationButton from '../buttons/navigation-button';
import { productionHost } from '../../services/axios-config';
import Image from '../../assets/Webinaarikuva.jpg';

function FileList() {
  const { scopes } = useAuthProvider();
  // folder name from url
  const { folderName } = useParams();
  const history = useHistory();

  const [openModal, setOpenModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileList = useQuery(['files', folderName], () =>
    api.getAllFilesInFolder(folderName),
  );

  // handle deleting files
  const queryClient = useQueryClient();
  const deleteFile = useMutation(
    () => api.deleteFile(folderName, selectedFile),
    {
      onSuccess: () => queryClient.invalidateQueries('files'),
    },
  );

  // error checking
  if (fileList.isLoading) return <p>Loading...</p>;
  if (fileList.isError)
    return (
      <>
        {errorMessageParser(fileList.error).map((message) => (
          <p>{message}</p>
        ))}
      </>
    );

  return (
    <div>
      <div
        className={styles.section}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <NavigationButton
            label="Back"
            icon="CornerUpLeft"
            to={() => history.goBack()}
          />
          <h1>File List</h1>
          {scopes.includes(`create:folders:${folderName}`) && (
            <NavigationButton
              label="Add new file"
              icon="FilePlus"
              to={() => history.push(`/files/${folderName}/create`)}
            />
          )}
        </div>
        <div
          style={{
            height: '200px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={Image} alt="" height="200px" />
        </div>
      </div>

      <table className={styles.list}>
        <thead>
          <tr>
            <th>Download</th>
            {scopes.includes(`delete:folders:${folderName}`) && <th>Delete</th>}
          </tr>
        </thead>
        <tbody>
          {fileList.data.files.map((file) => (
            <tr key={uuidv4()}>
              <td>
                <a
                  className={styles.link}
                  href={`${productionHost}/files/${folderName}/${file.fileName}/download`}
                >
                  {file.fileName}
                </a>
              </td>
              {scopes.includes(`delete:folders:${folderName}`) && (
                <td
                  className="trash"
                  onClick={() => {
                    setOpenModal(true);
                    setSelectedFile(file.fileName);
                  }}
                >
                  <Trash2 />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {fileList.data.files.length === 0 && <p>No files found.</p>}
      {openModal && (
        <Modal
          title="Delete file"
          body="Are you sure you want to delete this file?"
          cancel={() => {
            setOpenModal(false);
            setSelectedFile(null);
          }}
          confirm={() => {
            setOpenModal(false);
            deleteFile.mutate();
            setSelectedFile(null);
          }}
        />
      )}
    </div>
  );
}

export default FileList;
