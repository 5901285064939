import React, { useState } from 'react';
import { useAuthProvider } from '../../auth/auth-state';
import FormSubmitButton from '../buttons/form-submit-button';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login, errors } = useAuthProvider();

  const handleSubmit = (event) => {
    event.preventDefault();
    login(username, password);
  };

  return (
    <div>
      <h1>Log in</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="username">Username</label>
        <input
          id="username"
          type="text"
          name="username"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <label htmlFor="password">Password</label>
        <input
          id="password"
          type="password"
          name="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <FormSubmitButton label="Log in" icon="LogIn" />
      </form>
      {errors &&
        errors.map((message, i) => <p key={`${message}${i}`}>{message}</p>)}
    </div>
  );
}

export default Login;
