import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import api from '../../services/axios-calls';
import Modal from '../modal/modal-window.jsx';
import errorMessageParser from '../../utils/error-parser';
import { v4 as uuidv4 } from 'uuid';
import styles from './files.module.scss';
import { Settings, Trash2 } from 'react-feather';
import NavigationButton from '../buttons/navigation-button';
import { useAuthProvider } from '../../auth/auth-state';

function AdminFolderList() {
  const { scopes } = useAuthProvider();
  const [openModal, setOpenModal] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const history = useHistory();
  const folderList = useQuery(['afolders'], api.getAllFolders);

  // handle deleting folders
  const queryClient = useQueryClient();
  const deleteFolder = useMutation(api.deleteFolder, {
    onSuccess: () => queryClient.invalidateQueries('afolders'),
  });

  // error checking
  if (folderList.isLoading) return <p>Loading...</p>;
  if (folderList.isError)
    return (
      <>
        {errorMessageParser(folderList.error).map((message) => (
          <p>{message}</p>
        ))}
      </>
    );

  return (
    <div>
      <h1>Admin folder List</h1>
      {scopes.includes('create:folders') && (
        <NavigationButton
          label="Add new folder"
          icon="FolderPlus"
          to={() => history.push('/folders/create')}
        />
      )}
      <table className={styles.list}>
        <thead>
          <tr>
            <th>Folder</th>
            {scopes.includes('update:folders') && <th>Edit</th>}
            {scopes.includes('delete:folders') && <th>Delete</th>}
          </tr>
        </thead>
        <tbody>
          {folderList.data.folders.map((folder, i) => (
            <tr key={uuidv4()}>
              <td onClick={() => history.push(`/files/${folder.name}`)}>
                {folder.name}
              </td>
              {scopes.includes('update:folders') && (
                <td
                  className="edit"
                  onClick={() => {
                    history.push(`/folders/${folder.name}`);
                  }}
                >
                  <Settings />
                </td>
              )}
              {scopes.includes('delete:folders') && (
                <td
                  className="trash"
                  onClick={() => {
                    setOpenModal(true);
                    setSelectedFolder(folder.name);
                  }}
                >
                  <Trash2 />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {folderList.data.folders.length === 0 && <p>No folders found.</p>}
      {openModal && (
        <Modal
          title="Delete folder"
          body="Are you sure you want to delete this folder?"
          cancel={() => {
            setOpenModal(false);
            setSelectedFolder(null);
          }}
          confirm={() => {
            setOpenModal(false);
            deleteFolder.mutate(selectedFolder);
            setSelectedFolder(null);
          }}
        />
      )}
    </div>
  );
}

export default AdminFolderList;
