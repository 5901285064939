import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import api from '../../services/axios-calls';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { initScopeState } from '../../utils/create-checkboxes';
import { useAuthProvider } from '../../auth/auth-state';
import FormSubmitButton from '../buttons/form-submit-button';
import NavigationButton from '../buttons/navigation-button';

function EditRole() {
  // form state
  const [newName, setNewName] = useState('');
  const [newScopes, setNewScopes] = useState([]);
  const { checkSession } = useAuthProvider();

  // get query parameter from url
  const { name } = useParams();
  const history = useHistory();

  // fetch data from the server
  const scopesQuery = useQuery(['scopes'], api.getAllScopes);
  const roleQuery = useQuery(['roles', name], () => api.getRole(name));
  const queryClient = useQueryClient();

  // mutate server state
  const editRole = useMutation(
    async () => {
      const sendingScopes = scopesQuery.data.scopes.filter((scope, index) => {
        return newScopes[index].isChecked;
      });
      const formData = {
        newName,
        newScope: sendingScopes,
      };
      await api.updateRole(roleQuery.data.roles[0].name, formData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['roles']);
        // check session to refresh scopes of the logged in user
        checkSession();
      },
    },
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    editRole
      .mutateAsync()
      .then(() => {
        history.goBack();
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  // init form data and update it on refetches
  useEffect(() => {
    if (roleQuery.isSuccess && roleQuery.data !== undefined) {
      setNewName(roleQuery.data.roles[0].name);
    }
    if (
      roleQuery.isSuccess &&
      scopesQuery.isSuccess &&
      roleQuery.data !== undefined &&
      scopesQuery.data !== undefined
    ) {
      setNewScopes(
        initScopeState(scopesQuery.data.scopes, roleQuery.data.roles[0]),
      );
    }
  }, [
    roleQuery.data,
    roleQuery.isSuccess,
    scopesQuery.data,
    scopesQuery.isSuccess,
  ]);

  // error checking
  if (roleQuery.isLoading || scopesQuery.isLoading) return <p>Loading...</p>;
  if (roleQuery.isError) return <p>Error: {roleQuery.error.message}</p>;
  if (scopesQuery.isError) return <p>Error: {scopesQuery.error.message}</p>;

  return (
    <div>
      <NavigationButton
        label="Back"
        icon="CornerUpLeft"
        to={() => history.goBack()}
      />
      <h1>Edit role</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Name</label>
        <input
          id="name"
          type="text"
          name="name"
          value={newName}
          onChange={(event) => {
            setNewName(event.target.value);
          }}
        />
        {newScopes.map((scope, index) => {
          return (
            <div key={`${scope}-${index}`}>
              <input
                id={`${scope}-${index}`}
                name={scope.name}
                type="checkbox"
                checked={scope.isChecked}
                onChange={() => {
                  const arr = [...newScopes];
                  const value = !newScopes[index].isChecked;
                  arr[index].isChecked = value;
                  setNewScopes(arr);
                }}
              />
              <label htmlFor={`${scope}-${index}`}>{scope.name}</label>
            </div>
          );
        })}
        <FormSubmitButton label="Edit role" icon="UserCheck" />
      </form>
    </div>
  );
}

export default EditRole;
