import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styles from './navbar.module.scss';
import { useAuthProvider } from '../../auth/auth-state';
import { User, LogIn, LogOut } from 'react-feather';

function UserMenu() {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const { user, logout } = useAuthProvider();
  const history = useHistory();

  // User menu controls
  const toggleUserMenu = () => {
    setUserMenuOpen(!userMenuOpen);
  };
  const closeUserMenu = () => {
    setUserMenuOpen(false);
  };

  // Build user account menu
  const userMenu = user ? (
    <>
      <div
        className={styles.item}
        onClick={() => {
          history.push('/profile');
          closeUserMenu();
        }}
      >
        <User />
        Profile
      </div>
      <div
        className={styles.item}
        onClick={() => {
          logout();
          closeUserMenu();
        }}
      >
        <LogOut />
        Logout
      </div>
    </>
  ) : (
    <Link to="/login" onClick={closeUserMenu}>
      <div className={styles.item}>
        <LogIn />
        Login
      </div>
    </Link>
  );
  return (
    <>
      {/* Toggle */}
      <div className={styles.userToggle} onClick={toggleUserMenu}>
        <User />
      </div>

      {/* Menu */}
      {userMenuOpen ? (
        <nav className={styles.userMenu}>{userMenu}</nav>
      ) : (
        <nav className={`${styles.userMenu} ${styles.hide}`}>{userMenu}</nav>
      )}
    </>
  );
}

export default UserMenu;
