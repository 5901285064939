import React from 'react';
import * as IconLib from 'react-feather';

function Icon(props) {
  const type = props.icon;
  const MyIcon = IconLib[type];
  return <MyIcon />;
}

export default Icon;
