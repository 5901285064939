import React from 'react';
import styles from './buttons.module.scss';
import Icon from '../navbar/icon.jsx';

function FormSubmitButton(props) {
  const { label, icon } = props;
  return (
    <button className={styles.submit} type="submit">
      {icon && <Icon icon={icon} />}
      <span>{label}</span>
    </button>
  );
}

export default FormSubmitButton;
