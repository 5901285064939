import React, { useState, useEffect } from 'react';
import styles from './navbar.module.scss';
import { useAuthProvider } from '../../auth/auth-state';
import SideNav from './side-nav.jsx';
import UserMenu from './user-menu.jsx';
import Logo from '../../assets/logo.png';
import { useHistory } from 'react-router-dom';

function Navbar() {
  const [displayUser, setDisplayUser] = useState(null);
  const { user } = useAuthProvider();
  const history = useHistory();

  useEffect(() => {
    setDisplayUser(user);
  }, [user]);

  return (
    <nav className={styles.header}>
      <div className={styles.left}>
        <SideNav />
      </div>

      <div className={styles.middle}>
        <img
          className={styles.logo}
          src={Logo}
          alt="Brand"
          onClick={() => history.push('/')}
        />
        <span>Intranet</span>
      </div>

      <div className={styles.right}>
        <div>{displayUser}</div>
        <UserMenu />
      </div>
    </nav>
  );
}

export default Navbar;
