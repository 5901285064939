import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { AuthProvider } from './auth/auth-state.js';

// components
import Layout from './components/layout/layout.jsx';
import PrivateRoute from './components/routes/private-route.jsx';

import Home from './components/home/home.jsx';
import Login from './components/login/login.jsx';

import UserList from './components/users/user-list.jsx';
import AddUser from './components/users/add-user.jsx';
import EditUser from './components/users/edit-user.jsx';

import RoleList from './components/roles/role-list.jsx';
import AddRole from './components/roles/add-role.jsx';
import EditRole from './components/roles/edit-role.jsx';

import ScopeList from './components/scopes/scope-list.jsx';
import AddScope from './components/scopes/add-scope.jsx';
import EditScope from './components/scopes/edit-scope.jsx';

import AdminFolderList from './components/files/folder-list-admin.jsx';
import FolderList from './components/files/folder-list.jsx';
import AddFolder from './components/files/add-folder.jsx';
import FileList from './components/files/file-list.jsx';
import AddFile from './components/files/add-file.jsx';

import Profile from './components/users/profile.jsx';
import EditFolder from './components/files/edit-folder.jsx';

// initialize cache
const client = new QueryClient();

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={client}>
        <AuthProvider>
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/login" component={Login} />

              <PrivateRoute exact path="/profile" component={Profile} />

              <PrivateRoute exact path="/users" component={UserList} />
              <PrivateRoute exact path="/users/create" component={AddUser} />
              <PrivateRoute
                exact
                path="/users/:username"
                component={EditUser}
              />

              <PrivateRoute exact path="/roles" component={RoleList} />
              <PrivateRoute exact path="/roles/create" component={AddRole} />
              <PrivateRoute exact path="/roles/:name" component={EditRole} />

              <PrivateRoute exact path="/scopes" component={ScopeList} />
              <PrivateRoute exact path="/scopes/create" component={AddScope} />
              <PrivateRoute exact path="/scopes/:name" component={EditScope} />

              <PrivateRoute exact path="/folders" component={AdminFolderList} />
              <PrivateRoute
                exact
                path="/folders/create"
                component={AddFolder}
              />
              <PrivateRoute
                exact
                path="/folders/:name"
                component={EditFolder}
              />

              <PrivateRoute exact path="/files/" component={FolderList} />
              <PrivateRoute
                exact
                path="/files/:folderName"
                component={FileList}
              />
              <PrivateRoute
                exact
                path="/files/:folderName/create"
                component={AddFile}
              />
            </Switch>
          </Layout>
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
