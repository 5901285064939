import axios from 'axios';

export const productionHost = 'https://intra.osuuskuntaverkottaja.fi/api';

const axiosConfig = () => {
  if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = productionHost;
  } else {
    axios.defaults.baseURL = 'http://localhost:3001/api';
  }
  axios.defaults.headers.get['Content-Type'] = 'application/json';
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.patch['Content-Type'] = 'application/json';
  axios.defaults.headers.delete['Content-Type'] = 'application/json';
  axios.defaults.withCredentials = true;

  // add csrf token in header whenever server sends one in body
  axios.interceptors.response.use(function (res) {
    const { csrf } = res.data;
    if (csrf) {
      axios.defaults.headers['x-xsrf-token'] = csrf;
    }
    return res;
  });
};

export default axiosConfig;
