import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import api from '../../services/axios-calls';
import Modal from '../modal/modal-window.jsx';
import errorMessageParser from '../../utils/error-parser';
import { v4 as uuidv4 } from 'uuid';
import styles from './files.module.scss';
import { useAuthProvider } from '../../auth/auth-state';
import Image from '../../assets/Businesswoman.jpg';

function FolderList() {
  const { scopes } = useAuthProvider();
  const [openModal, setOpenModal] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const history = useHistory();
  const folderList = useQuery(['folders'], api.getAllFileFolders);

  // handle deleting folders
  const queryClient = useQueryClient();
  const deleteFolder = useMutation(api.deleteFolder, {
    onSuccess: () => queryClient.invalidateQueries('folders'),
  });

  // error checking
  if (folderList.isLoading) return <p>Loading...</p>;
  if (folderList.isError)
    return (
      <>
        {errorMessageParser(folderList.error).map((message) => (
          <p>{message}</p>
        ))}
      </>
    );

  return (
    <>
      <div
        className={styles.section}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <h1>Folder List</h1>
        <div>
          <img src={Image} alt="" height="200px" />
        </div>
      </div>
      <table className={styles.list}>
        <thead>
          <tr>
            <th>Folder</th>
          </tr>
        </thead>
        <tbody>
          {folderList.data.folders.map((folder, i) => (
            <tr key={uuidv4()}>
              <td onClick={() => history.push(`/files/${folder.name}`)}>
                {folder.name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {folderList.data.folders.length === 0 && <p>No folders found.</p>}
      {openModal && (
        <Modal
          title="Delete folder"
          body="Are you sure you want to delete this folder?"
          cancel={() => {
            setOpenModal(false);
            setSelectedFolder(null);
          }}
          confirm={() => {
            setOpenModal(false);
            deleteFolder.mutate(selectedFolder);
            setSelectedFolder(null);
          }}
        />
      )}
    </>
  );
}

export default FolderList;
