import React, { useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuthProvider } from '../../auth/auth-state';

// https://medium.com/@tomlarge/private-routes-with-react-router-dom-28e9f40c7146
function PrivateRoute({ component: Component, ...rest }) {
  const { user, checkSession } = useAuthProvider();
  const [isLoading, setIsLoading] = useState(true);

  // query server for an active session to load user on page refreshes
  // but only do it once, hence [] deps, to avoid infinite loops
  // functions that change user data are responsible for calling
  // checkSession() on their own
  useEffect(() => {
    let cancel = false;
    if (!cancel) {
      checkSession().then(() => setIsLoading(false));
    }
    return () => {
      cancel = true;
    };
  }, []); //eslint-disable-line

  // don't render anything before session check is done
  if (isLoading) return null;

  // render component or redirect based on results
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
