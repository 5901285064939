/**
 * Error parser that returns an array of error messages.
 * map() over the array to render messages.
 *
 * @param {Object} error - Error response object
 * @returns {Array} Array of error messages as strings
 */
export default function errorMessageParser(error) {
  if (error.response) {
    // Request made and server responded
    return error.response.data.errors;
  } else if (error.request) {
    // The request was made but no response was received
    return ['Server did not respond.'];
  } else {
    // Something happened in setting up the request that triggered an Error
    return ['Something went wrong.'];
  }
}
