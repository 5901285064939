import React from 'react';
import ReactDOM from 'react-dom';
import App from './app.jsx';
import './styles/styles.scss';
import axiosConfig from './services/axios-config';

axiosConfig();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
