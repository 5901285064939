import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../services/axios-calls';
import NavigationButton from '../buttons/navigation-button';
import { useHistory } from 'react-router-dom';
import styles from './files.module.scss';
import FormSubmitButton from '../buttons/form-submit-button';
import { useQueryClient, useMutation } from 'react-query';
import { useAuthProvider } from '../../auth/auth-state';
import errorMessageParser from '../../utils/error-parser';

function AddFile() {
  const { checkSession } = useAuthProvider();
  const { folderName } = useParams();
  const history = useHistory();
  const [singleFile, setSingleFile] = useState(null);

  // file inputs are controlled and need a ref for clearing
  const fileRef = useRef();
  const queryClient = useQueryClient();

  const addFile = useMutation(
    async () => await api.createFile(folderName, singleFile),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('files');
        checkSession();
      },
    },
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    if (singleFile == null) return;
    addFile
      .mutateAsync()
      .then(() => {
        // clear the value property on the input DOM node
        fileRef.current.value = '';
        history.goBack();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <NavigationButton
        label="Back"
        icon="CornerUpLeft"
        to={() => history.goBack()}
      />
      <h1>Upload File</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className={styles.buttonWrap}>
          <label className={styles.button} htmlFor="file">
            Select file
          </label>
          <input
            className={styles.input}
            type="file"
            name="file"
            id="file"
            ref={fileRef}
            onChange={(event) => {
              setSingleFile(event.target.files[0]);
            }}
          />
        </div>
        <FormSubmitButton label="Upload" icon="Upload" />
      </form>
      {addFile.isError && (
        <>
          {errorMessageParser(addFile.error).map((message) => (
            <p>{message}</p>
          ))}
        </>
      )}
    </div>
  );
}

export default AddFile;
